div.app-container {
    width: 100%;
    max-width: 1000px;
    margin: auto;
    padding-top: 100px;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
}

@media only screen and (max-width: 600px) {
    div.app-container {
        padding-top: 60px;
        padding-left: 20px;
        padding-right: 20px;
    }
}