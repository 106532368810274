h3.section-title {
    text-align: center;
    font-size: 2rem;
    margin-top: 100px;
}

@media only screen and (max-width: 600px) {
    h3.section-title {
        font-size: 1.5rem;
        margin-top: 60px;
    }
}