div.experience {
    margin-bottom: 40px;
}

div.experience div.title-container {
    width: 300px;
    padding-right: 20px;
}

div.experience h3.title {
    margin-top: 0px;
    margin-bottom: 5px;
    font-weight: 900;
    font-size: 1rem;
}

div.experience div.title-container > h4.subtitle {
    margin: 0px;
    font-size: 0.9rem;
    color: #757575;
}

div.content-container {
    flex: 1;
}

div.content-container > p {
    font-size: 0.9rem;
    color: #757575;
}

@media only screen and (max-width: 600px) {
    div.experience div.title-container {
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 600px) {
    div.experience {
        display: flex;
    }
}