div.bio-section > div.text-container > h6.subtitle {
    margin-top: 10px;
    color: #757575;
    font-size: 0.9rem;
    font-weight: bolder;
}

div.bio-section > div.text-container > hr {
    width: 50px;
    border: 1px solid lightgray;
    margin-left: 0px;
}

div.bio-section > img.profile-small-screen {
    display: none;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin: auto;
}

div.bio-section > img.profile-large-screen {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
}

@media only screen and (max-width: 600px) {
    div.bio-section > div.text-container {
        text-align: center;
    }

    div.bio-section > div.text-container > h1.title {
        font-size: 1.5rem;
    }

    div.bio-section > div.text-container > hr {
        margin: auto;
    }

    div.bio-section > div.text-container > p.short-bio {
        font-size: 0.9rem;
    }

    div.bio-section > img.profile-small-screen {
        display: block;
    }

    div.bio-section > img.profile-large-screen {
        display: none;
    }
  }

  @media only screen and (min-width: 600px) {
    div.bio-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    div.bio-section > div.text-container {
        margin-right: 100px;
    }
  }